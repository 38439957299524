'use client'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import Image from 'next/image'
import arrowIcon from '../../../app/images/arrow-down-circle.svg'

export default function SubmenuLinks({
  submenuItemsArray,
  className,
  children,
}: {
  submenuItemsArray: any
  className?: string
  children: React.ReactNode
}) {
  return (
    <Dropdown
      radius="none"
      className={`border-t border-l border-primary rounded pointer
        border-solid border-1 border-r-3 border-b-3 shadow-none`}
    >
      <DropdownTrigger className={'text-black cursor-pointer ' + className}>
        <div className="flex font-medium gap-0.5">
          <div className="hover:underline text-black">{children}</div>
          <div>
            <Image width={12} height={12} className='mt-2' alt="arrow icon" src={arrowIcon} />
          </div>
        </div>
      </DropdownTrigger>
      <DropdownMenu className="" aria-label="Static Actions">
        {submenuItemsArray?.map((link: any, index: any) => (
          <DropdownItem key={index} href={link.href}>
            {link.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
