'use client'
import { useError } from '@jobmojito/components/providers/ErrorProvider'
import { Modal, ModalBody, ModalContent } from '@nextui-org/modal'

export default function ErrorModal() {
  const { error, type, clearError } = useError()

  return (
    <>
      {/*<Button onPress={onOpen}>Open Modal</Button>*/}
      <Modal
        backdrop={'transparent'}
        className={
          `rounded-[5px] max-w-[400px] w-auto pe-5 ${(type==='success'?'text-success bg-success-50':'text-danger bg-default-800 border-default-800')}`
        }
        classNames={{ wrapper: 'sm:items-start' }}
        isOpen={!!error}
        onClose={clearError}
        placement={'top-center'}
      >
        <ModalContent>
          <ModalBody className={'flex flex-col gap-1'}>{error}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
