import getClientSupabase from '../getClientSupabase'

export default function getProfile({
  setProfileState,
}: {
  setProfileState: any
}) {
  const supabase = getClientSupabase()

  supabase.auth
    .getSession()
    .then(({ data: session, error }: { data: any; error: any }) => {
      if (error) {
        console.error(error)
        return
      }

      if (session?.session?.user?.app_metadata.profile_id) {
        supabase
          .from('profile')
          .select('avatar_url, name, email, config_features')
          .eq('id', session?.session?.user?.app_metadata.profile_id)
          .single()
          .then((res) => {
            const { data } = res
            setProfileState(data)
          })
      }
    })
}
