'use client'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'
import getMainDomain from './getMainDomain'
import useCategoryCookieConsent from './useCategoryCookieConsent'

export default function useAffiliateCookieCleanup() {
  const marketingConsent = useCategoryCookieConsent('marketing')

  useEffect(() => {
    if (typeof window !== 'undefined' && marketingConsent !== undefined) {
      if (marketingConsent !== true) {
        removeAffiliateIdCookies()
      }
    }
  }, [marketingConsent])
}

export function removeAffiliateIdCookies() {
  const mainDomain = getMainDomain()
  const consentMarketingGiven = CookieConsent.acceptedCategory('marketing')
  if (consentMarketingGiven !== true) {
    Cookies.remove('affiliate_id', {
      path: '/',
      domain: '.' + mainDomain,
      sameSite: 'None',
    })
  }
}
