'use client'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'

export default function useCategoryCookieConsent(
  categoryName: 'analytics' | 'marketing',
) {
  const [cookieConsent, setCookieConsent] = React.useState<
    boolean | undefined
  >()

  useEffect(() => {
    if (typeof window !== 'undefined' && CookieConsent) {
      const ccCookieExists = Cookies.get('cc_cookie') // Check cc_cookie exists
      let consentGiven = true // Cookies are allowed by default

      if (ccCookieExists) {
        // If cc_cookie is exist, check the analytics consent
        consentGiven = CookieConsent.acceptedCategory(categoryName)
      }

      setCookieConsent(consentGiven)
    }
  }, [])

  return cookieConsent
}
