'use client'
import { getUser } from '@jobmojito/app/utils/auth/getUser'
import getMainDomain from '@jobmojito/app/utils/cookies/getMainDomain'
import useCategoryCookieConsent from '@jobmojito/app/utils/cookies/useCategoryCookieConsent'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import Cookies from 'js-cookie'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { ReactNode, useEffect } from 'react'
import { getProfileHash } from '@jobmojito/app'

export function CSPostHogProvider({ children }: { children: ReactNode }) {
  const analyticsConsent = useCategoryCookieConsent('analytics')

  useEffect(() => {
    if (typeof window !== 'undefined' && analyticsConsent !== undefined) {
      if (analyticsConsent !== true) {
        removePosthogCookies()
      }

      // Initialize posthog
      initPostHog(analyticsConsent)
    }
  }, [analyticsConsent])

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

async function getUserId() {
  const supabase = getClientSupabase()
  let userId = null
  try {
    const user = await getUser(supabase)
    userId = user?.id
  } catch (error) {
    // no user
  }
  return userId
}

async function initPostHog(analyticsConsent: boolean) {
  const userId = await getUserId()
  const distinctId = userId ? { distinctID: getProfileHash(userId) } : {}

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    persistence: analyticsConsent !== true ? 'memory' : 'cookie',
    bootstrap: distinctId,
  })

  if (userId) {
    posthog.identify(getProfileHash(userId))
  }
}

export async function updatePostHogPersistence(analyticsDenied: boolean) {
  posthog.set_config({ persistence: analyticsDenied ? 'memory' : 'cookie' })

  const userId = await getUserId()
  if (userId) {
    const distinctId = getProfileHash(userId)
    posthog.identify(distinctId)
  }
}

function removePosthogCookies() {
  const cookies = document.cookie.split(';')
  const postHogCookiePrefix = 'ph_phc_'

  const mainDomain = getMainDomain()

  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim()
    if (cookieName.startsWith(postHogCookiePrefix)) {
      Cookies.remove(cookieName, {
        path: '/',
        domain: '.' + mainDomain,
        sameSite: 'lax',
      })
    }
  })
}

