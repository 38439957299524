import { SupabaseClient } from '@supabase/supabase-js'

export async function getUser(supabase: SupabaseClient) {
  try {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser()
    return user
  } catch (error) {
    // user is not logged in
    return null
  }
}
