import React from 'react'
import Link from 'next/link'

// Define the shape of each menu item
interface MenuItem {
  href: string // URL the menu item should navigate to
  text: string // Text to display for the menu item
  className?: string // Additional classes for the menu item
}

// MenuLinks component to render a list of navigation links
export default function HeaderLinks({
  className,
  menuLinksArray,
  closeMenu,
}: {
  menuLinksArray: MenuItem[]
  closeMenu: () => void
  className?: string
}) {
  return (
    <div className={' ' + className}>
      {menuLinksArray?.map((link: any, index: any) => {
        const isActive = false
        return (<Link
            onClick={() => closeMenu()}
            key={index}
            className={
              `font-medium text-black hover:text-primary xl:mx-2 block ` +
              (isActive ? 'font-bold underline ' : 'hover:underline ') +
              link.className
            }
            href={link.href}
          >
            {link.text}
          </Link>
        )
      })}
    </div>
  )
}
