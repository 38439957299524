// Escape hatch for packages not updated to 'use client' yet
"use client";

import { ErrorProvider } from "@jobmojito/components/providers/ErrorProvider";
import { NextUIProvider } from "@nextui-org/react";
import React from "react";
import { CSPostHogProvider } from "@jobmojito/components/providers/posthog";
import useAffiliateCookieCleanup from '@jobmojito/app/utils/cookies/useAffiliateCookieCleanup'

export function Providers({ children }: { children: React.ReactNode }) {
  useAffiliateCookieCleanup()
  return (
    <NextUIProvider>
      <ErrorProvider>
        <CSPostHogProvider>{children}</CSPostHogProvider>
      </ErrorProvider>
    </NextUIProvider>
  )
}
