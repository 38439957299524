'use client'
import HeaderLinks from '@jobmojito/components/navigation/Header/HeaderLinks'
import {
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  Navbar as NextUiNavbar,
} from '@nextui-org/navbar'
import NextImage from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import ProfileDisplay from './ProfileDisplay'

export default function Header({
  children,
  className = '',
  logoClassName,
  menuLinksArray,
  logoImage,
  logoImageAlt = 'logo icon',
  guestView,
  userView,
}: {
  children?: React.ReactNode
  className?: string
  logoClassName?: string
  menuLinksArray?: any
  logoImage?: any
  logoImageAlt?: string
  guestView: React.ReactNode
  userView: React.ReactNode
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      maxWidth={'xl'}
      className={'text-base w-screen fixed top-0 w-full'}
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className="items-end flex">
        {logoImage && (
          <Link className="" href="/">
            <NextImage
              className={' ' + logoClassName}
              src={logoImage}
              alt={logoImageAlt}
            />
          </Link>
        )}
      </div>

      <NavbarContent className="hidden xl:flex flex-row grow-0" justify={'end'}>
        <div className="flex items-center space-x-4">
          <div className="flex items-center gap-4">{children}</div>

          <HeaderLinks
            className="flex"
            menuLinksArray={menuLinksArray}
            closeMenu={() => closeMenu()}
          />

          <div className={'w-[1px] h-[20px] bg-primary'} />
          <ProfileDisplay guestView={guestView} userView={userView} />
        </div>
      </NavbarContent>

      <NavbarMenuToggle
        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        className="xl:hidden"
      />
      <NavbarMenu>
        <div className="text-xl flex flex-col items-start mt-5 gap-2">
          <div className="flex "></div>
          {children}
          <HeaderLinks
            className="flex flex-col gap-2"
            menuLinksArray={menuLinksArray}
            closeMenu={() => closeMenu()}
          />
          <ProfileDisplay guestView={guestView} userView={userView} />
        </div>
      </NavbarMenu>
    </NextUiNavbar>
  )
}
