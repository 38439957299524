export default function getMainDomain() {
  // Set the initial domain
  let mainDomain = window.location.hostname
  const domainParts = mainDomain.split('.')
  // Check if the domain has more than two parts and is not localhost or IP address
  if (domainParts.length >= 2) {
    mainDomain = domainParts.slice(-2).join('.')
  }
  return mainDomain
}
