'use client'
import React, { useEffect, useState } from 'react'
import getProfile from '@jobmojito/app/utils/auth/getProfile'

export default function ProfileDisplay({
  userView,
  guestView,
}: {
  guestView: React.ReactNode
  userView: React.ReactNode
}) {
  const [profile, setProfile] = useState<any>(null)

  useEffect(() => {
    const profileData = getProfile({ setProfileState: setProfile })
    setProfile(profileData)
  }, [])

  return <>{profile ? userView : guestView}</>
}
